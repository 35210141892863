<template>
  <v-parallax src="https://cdn.vuetifyjs.com/images/parallax/material.jpg">
    <vue-typed-js :strings="mountTypedArray()" :loop="true" class="mx-auto">
      <h2><span class="typing"></span></h2>
    </vue-typed-js>
  </v-parallax>
</template>
<script>
import Vue from "vue";
import VueTypedJs from "vue-typed-js";

Vue.use(VueTypedJs);
export default {
  props: {
    content: {
      type: Object
    }
  },
  data() {
    return {
      typedArray: []
    };
  },
  methods: {
    mountTypedArray() {
      return [`${this.content.titulo} ^1000`, this.content.texto];
    }
  }
};
</script>
