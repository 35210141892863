<template>
  <v-app>
    <div
      v-if="loading"
      style="display: flex;align-items: center;justify-content: center;margin: 5em; "
    >
      <v-progress-circular
        :size="70"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <Paralax v-if="!loading" :content="content" />
    <h1 v-if="!loading" class="mx-auto my-4">Sobre a Empresa</h1>
    <About v-if="!loading" :content="about" />
    <!-- h1 class="mx-auto my-4">Nossa história</h1>
    <v-row justify="center" class="mx-5">
      <v-col lg="10" sm="10" md="10">
        <Timeline />
      </v-col>
    </v-row-->
  </v-app>
</template>
<script>
import Paralax from "@/components/paralax.vue";
import About from "@/components/about.vue";
import { RepositoryFactory } from "@/repositories/factory";

const ContentRepository = RepositoryFactory.get("contents");
export default {
  components: {
    Paralax,
    About
  },
  data() {
    return {
      content: null,
      about: null,
      ABOUT_SCREEN: 2,
      loading: false
    };
  },
  created() {
    this.fetchTexts();
    this.$store.dispatch('insertCount');
  },
  methods: {
    fetchTexts() {
      this.loading = true;
      ContentRepository.findTextsByScreen(this.ABOUT_SCREEN)
        .then(({ data }) => {
          [this.content, this.about] = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style>
.v-application--wrap {
  min-height: 7vh !important;
}
</style>
