<template>
  <v-card class="mx-11 px-6 px-2 py-6 text-justify">
    {{ content.texto }}
  </v-card>
</template>
<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => ({
        titulo: "",
        texto: `
          Polidesign Indústria e Comércio Ltda fundada em 1994, operando na atividade
          de industrialização de equipamentos eletrônicos, iniciou fazendo montagem de
          placas de circuitos eletrônicos para automação de elevadores, fabricação e
          venda de receptor de satélite, assim como os componentes de sistema de
          recepção de TV como chave coaxial, divisores e aumentando sua capacidade de
          produção montando placa de rádio monocanal para telefonia celular.
          Atualmente, iniciou a fabricação de antenas profissionais para transmissão
          de sinais de TV em VHF, UHF, microondas e Rádio FM para baixa, média e alta
          potência de transmissão. Para isso, montou um corpo técnico com grande
          experiência em desenvolvimento, industrialização e manutenção desses
          produtos, capaz de desenvolver os mais sofisticados projetos solicitados
          pelo mercado.
        `
      })
    }
  }
};
</script>
